<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Add WhatsApp Numbers</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious"
                        icon="pi pi-chevron-left" />
                    <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm"
                        iconPos="right" />
                </div>
            </div>
            <div class="card">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Client Name : {{ this.localClientName ? this.localClientName : '-' }} </h6>
                    </span>
                </div>
                <div style="position: relative">
                    <div class="p-px-3">
                        <div class="p-formgrid p-grid">
                            <DataTable :value="whatsnolist" :lazy="true" :paginator="false" :rows="30"
                                :totalRecords="totalRecords" :loading="loading" class="p-datatable-users" data-key="cq1"
                                :rowHover="true"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                                <Toolbar>
                                    <template v-slot:right>
                                        <Button class="p-mr-2" label="Add WhatsApp No." icon="pi pi-plus"
                                            @click="onAddWhatsAppNo" />
                                    </template>
                                </Toolbar>
                                <template #empty> No data found. </template>
                                <template #loading> Loading data. Please wait... </template>
                                <Column header="Sr." headerStyle="width: 4%">
                                    <template #body="{ index }">
                                        <div>{{ genrateSrNo(index) }}</div>
                                    </template>
                                </Column>
                                <Column header="WhatsApp No." headerStyle="width: 10%">
                                    <template #body="{ data }">
                                        <div>{{ data.cq4 ? data.cq4 : "-" }}</div>
                                    </template>
                                </Column>
                                <Column header="WhatsApp Url" headerStyle="width: 35%">
                                    <template #body="{ data }">
                                        <div>{{ data.cq6 ? data.cq6 : "-" }}</div>
                                    </template>
                                </Column>
                                <!-- <Column header="WhatsApp Access Key" headerStyle="width: 25%">
                                    <template #body="{ data }">
                                        <div>{{ data.cq5 ? data.cq5 : "-" }}</div>
                                    </template>
                                </Column> -->
                                <Column header="Status" headerStyle="width: 10%">
                                    <template #body="{ data }">
                                        <span v-if="data.cq8 == 1">Active</span>
                                        <span v-if="data.cq8 == 0">Inactive</span>
                                    </template>
                                </Column>
                                <Column header="Action" headerStyle="width: 10%">
                                    <template #body="{ data }">
                                        <Button label="Edit" class="p-button-link" @click="onWhatsAppNoEdit(data)" />
                                    </template>
                                </Column>
                            </DataTable>
                            <Toast />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add Number Dialog starts here -->
    <Dialog class="p-fluid" v-model:visible="isAddWhatsAppNo" :header="header" :style="{ width: '800px' }"
        :modal="true">
        <div style="position: relative">
            <div class="p-pt-2 p-px-3">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <label for="whatsapp_number">
                            WhatsApp Number
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <InputText id="whatsapp_number" v-model="whatsapp_number" autocomplete="off" autofocus
                            maxlength="12" :class="{ 'p-invalid': isSubmitted && !whatsapp_number }"
                            @keypress="onlyNumber" />
                        <small class="p-invalid p-error" v-if="v$.whatsapp_number.$error">{{
                            v$.whatsapp_number.$errors[0].$message
                        }}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="whatsapp_url">
                            URL
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <InputText id="whatsapp_url" v-model.trim="whatsapp_url" required="true" autofocus
                            maxlength="150" :class="{ 'p-invalid': isSubmitted && !whatsapp_url }" />
                        <small class="p-invalid p-error" v-if="v$.whatsapp_url.$error">{{
                            v$.whatsapp_url.$errors[0].$message }}</small>
                        <small class="p-invalid p-error" v-if="whatsapp_url_error">{{ whatsapp_url_error }}</small>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                @click="addWhatsAppNo" style="width: 90px;"></Button>
            <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button>
        </template>
    </Dialog>
    <!-- Add Number Dialog ends here -->
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { useRoute } from 'vue-router';
import router from '@/router';
import { required, helpers, numeric } from '@vuelidate/validators';

export default {
    data() {
        return {
            v$: useValidate(),
            whatsnolist: [],
            isAddWhatsAppNo: false,
            isSubmitted: false,
            componentName: "",
            showLoader: false,
            loading: false,
            page_no: 0,
            totalRecords: 0,
            componentNamesList: [],
            whatsapp_number: '',
            whatsapp_url: '',
            routeParam: '',
            localClientName: '',
            whatsapp_url_error: '',
            active_status: 1,
            row_id: 0,
            header: ""
        };
    },
    validations() {
        return {
            whatsapp_number: { required: helpers.withMessage('Please enter WhatsApp Number', required), numeric: helpers.withMessage('Only numbers are allowed', numeric) },
            whatsapp_url: { required: helpers.withMessage('Please enter URL', required) },
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        const route = useRoute();
        this.routeParam = route.params.clientSubId;
        this.localClientName = localStorage.localClientName;
        this.getClientsWhatsappNumber();
    },
    methods: {
        onAddWhatsAppNo() {
            this.v$.$reset();
            this.isAddWhatsAppNo = true;
            this.isSubmitted = false;
            this.whatsapp_number = '';
            this.whatsapp_url = '';
            this.header = "Add WhatsApp Number"
        },
        onWhatsAppNoEdit(e) {
            this.v$.$reset();
            this.isAddWhatsAppNo = true;
            this.isSubmitted = false;
            this.header = "Edit WhatsApp Number"
            this.whatsapp_number = e.cq4;
            this.whatsapp_url = e.cq6;
            this.row_id = e.cq1;
        },
        addWhatsAppNo() {
            this.isSubmitted = true;
            this.v$.$validate();
            var fields = {};
            fields["cq1"] = this.row_id;
            fields["cq2"] = this.routeParam;
            fields["cq4"] = this.whatsapp_number;
            fields["cq6"] = this.whatsapp_url;
            fields["cq8"] = this.active_status;
            if (!this.v$.$error) {
                this.showLoader = true;
                this.ApiService.createUpdateWhatsAppNumber(fields).then((items) => {
                    if (items.status == 200) {
                        this.showLoader = false;
                        this.isAddWhatsAppNo = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.whatsapp_number = '';
                        this.whatsapp_url = '';
                        this.row_id = 0;
                        this.getClientsWhatsappNumber();
                    } else {
                        this.isAddWhatsAppNo = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                        this.showLoader = false;
                    }
                });
            }
        },
        goToDashboard() {
            router.push({
                name: "clientdashboard",
                params: { clientId: this.routeParam },
            });
        },
        goToPrevious() {
            router.push({
                name: "web-bot-template-settings-dashboard",
                params: { clientSubId: this.routeParam, clientnamevalue: this.localClientName },

            });
        },
        goToNext() {
            router.push({
                name: "banner-images",
                params: { clientSubId: this.routeParam, clientnamevalue: this.localClientName },

            });
        },
        async getClientsWhatsappNumber() {
            try {
                const result = await this.ApiService.getClientsWhatsappNumber({ clientId: this.routeParam });
                this.whatsnolist = result.result;
                this.totalRecords = result.count;
                this.loading = false;
                if (!result.success) {
                    this.loading = false;
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            } catch (error) {
                this.loading = false;
                return this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            }
        }
    }
};
</script>

<style scoped></style>
